<template>
  <section v-if="mayor" class="section__mayor-about section__divider">
    <div class="mayor__about">
      <div
        v-if="mayor.banner_img"
        class="mayor__bg"
        :style="
          'background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url( ' +
          $store.state.api +
          mayor.banner_img.img.url +
          ')'
        "
      ></div>
      <div class="mayor__img-wrap">
        <div class="mayor__img">
          <div class="img__container">
            <img :src="mayor.head_img | image($store.state.api)" :alt="mayor.head_img | image_alt" />
          </div>
        </div>
      </div>
      <div class="mayor__about-info">
        <div v-if="mayor.surname || mayor.name || mayor.patronymic" class="mayor__about-name">
          {{ mayor.surname }} <br />
          {{ mayor.name }} <br />
          {{ mayor.patronymic }}
        </div>

        <div class="mayor__about-bottom">
          <span v-if="mayor.description" class="mayor__about-post">{{ mayor.description }}</span>
          <ul class="mayor__socials-list">
            <li v-if="mayor.email" class="mayor__socials-item">
              <a :href="'mailto:' + mayor.email">
                <MailIcon />
              </a>
            </li>
            <li v-if="mayor.phone" class="mayor__socials-item">
              <a :href="'tel:' + mayor.phone">
                <PhoneIcon />
              </a>
            </li>
            <li v-for="(item, index) in socials" :key="index" class="mayor__socials-item">
              <a :href="item.link" target="_blank">
                <i class="icon-big" v-html="item.icon"> </i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="mayor__biography">
      <h2>Биография</h2>
      <div v-if="stages && stages.length" class="mayor__biography-slider">
        <swiper ref="bigSwiper" :options="swiperOptions">
          <swiper-slide v-for="(item, index) in stages" :key="index">
            <div class="slide">
              <div class="mayor__biography-years" v-if="item.title">
                {{ item.title }}
              </div>
              <div class="mayor__biography-text" v-if="item.description">
                {{ item.description }}
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <a @click.prevent="bigSwiper.slidePrev()" class="slider__controls__left">
          <ArrowLeft />
        </a>
        <a @click.prevent="bigSwiper.slideNext()" class="slider__controls__right">
          <ArrowRight />
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import ArrowLeft from "@/components/svg/ArrowLeft.vue";
import ArrowRight from "@/components/svg/ArrowRight.vue";
import MailIcon from "@/components/svg/MailIcon.vue";
import PhoneIcon from "@/components/svg/PhoneIcon.vue";

export default {
  name: "MayorAboutComponent",
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1.1,
        loop: false,
        clickable: true,
        speed: 800,
        spaceBetween: 8,
        breakpoints: {
          420: {
            slidesPerView: 1.2,
            spaceBetween: 8,
          },
          460: {
            slidesPerView: 1.3,
            spaceBetween: 8,
          },
          520: {
            slidesPerView: 1.5,
            spaceBetween: 8,
          },
          660: {
            slidesPerView: 2,
            spaceBetween: 8,
          },
          768: {
            slidesPerView: 2.2,
            spaceBetween: 16,
          },
          860: {
            slidesPerView: 2.5,
            spaceBetween: 16,
          },
          990: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
        },
      },
    };
  },
  computed: {
    bigSwiper() {
      return this.$refs.bigSwiper.$swiper;
    },
    mayor() {
      return this.$store.state?.mayor_page.mayor;
    },
    socials() {
      return this.$store.state?.mayor_page.socials;
    },
    stages() {
      return this.$store.state?.mayor_page.stages;
    },
  },
  directives: {
    swiper: directive,
  },
  components: {
    Swiper,
    SwiperSlide,
    ArrowLeft,
    ArrowRight,
    MailIcon,
    PhoneIcon,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
@import "~@/styles/parts/swiper.styl"
@import "~@/styles/parts/mayor/index.styl"

.section__mayor-about {
  position relative
}

.gallery {
  &__header {
    display flex
    justify-content space-between
    flex-wrap wrap
    gap 16px
    padding 0 56px
    margin-bottom: 32px;
    +below(1200px) {
      padding 0
    }
    +below(768px) {
      margin-bottom: 16px;
    }

    .title {
      margin 0
    }
  }

  &__tabs-list {
    display flex
    flex-direction row
  }

  &__tab {
    margin-right calc(var(--margin) * 2)
    font-size: 1.125em;
    line-height: 28px
    +below(768px) {
      margin-right: 24px;
      font-size 1em
      line-height 20px
    }

    &:last-child {
      margin-right: 0;
    }

    > a {
      white-space nowrap
      color var(--color_gray_dark)
      padding 8px 0

      &:hover {
        color var(--color_black)
      }

      &.active {
        color var(--color_black)

        &:after {
          content ''
          display block
          margin-top 8px
          width 100%
          height 3px
          background-color var(--color_blue)
          animation-name line
          animation-duration 0.3s
          animation-timing-function: cubic-bezier(0.51, 0.92, 0.24, 1.15);
        }
      }
    }
  }

  &__tab-content {
    column-count: 3;
    column-gap: 1em;
    +below(768px) {
      column-count 2
    }
    +below(420px) {
      column-count 1
    }
  }

  &__img {
    display: inline-block;
    margin-bottom: var(--margin);
    width: 100%;

    img {
      border-radius: var(--radius);
    }
  }
}
</style>
