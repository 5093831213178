<template>
  <section
    v-if="
      (favoritePhotos && favoritePhotos.data && favoritePhotos.data.length) ||
      (officialPhotos && officialPhotos.data && officialPhotos.data.length)
    "
    class="section"
  >
    <div class="gallery__header">
      <h2 class="title">Фотогалерея</h2>

      <ul class="gallery__tabs-list">
        <li v-if="favoritePhotos && favoritePhotos.data && favoritePhotos.data.length" class="gallery__tab">
          <a @click.prevent="setActive(0)" :class="{ active: activeTab === 0 }"> Избранные </a>
        </li>
        <li v-if="officialPhotos && officialPhotos.data && officialPhotos.data.length" class="gallery__tab">
          <a @click.prevent="setActive(1)" :class="{ active: activeTab === 1 }"> Официальные </a>
        </li>
      </ul>
    </div>

    <div class="gallery__body">
      <div
        v-if="favoritePhotos && favoritePhotos.data && favoritePhotos.data.length"
        v-show="activeTab === 0"
        class="gallery__tab-content _items_list"
        id="lightgallery"
      >
        <a
          :href="item | image($store.state.api)"
          v-for="(item, index) in favoritePhotos.data"
          :key="index"
          class="gallery__img"
        >
          <img :src="item | image($store.state.api)" :alt="item | image_alt" />
        </a>
      </div>
      <div
        v-if="officialPhotos && officialPhotos.data && officialPhotos.data.length"
        v-show="activeTab === 1"
        class="gallery__tab-content _items_list"
        id="lightgallery2"
      >
        <a
          :href="item | image($store.state.api)"
          v-for="(item, index) in officialPhotos.data"
          :key="index"
          class="gallery__img"
        >
          <img :src="item | image($store.state.api)" :alt="item | image_alt" />
        </a>
      </div>

      <a v-if="loading" href="#" class="show-more-link">
        <LoadingIndicator title="Загрузка" />
      </a>
      <a
        v-else-if="favoritePhotos.next_page_url"
        v-show="activeTab === 0"
        @click.prevent="loadMore(0)"
        href="#"
        class="show-more-link"
      >
        Показать ещё
      </a>
      <a
        v-else-if="officialPhotos.next_page_url"
        v-show="activeTab === 1"
        @click.prevent="loadMore(1)"
        href="#"
        class="show-more-link"
      >
        Показать ещё
      </a>
    </div>
  </section>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import axios from "axios";

export default {
  name: "MayorGalleryComponent",
  components: {
    LoadingIndicator,
  },
  data() {
    return {
      loading: false,
      activeTab: 0,
    };
  },
  // mounted() {
  //   // eslint-disable-next-line no-undef
  //   require(["lightgallery.js"], () => {
  //     // eslint-disable-next-line no-undef
  //     require(["lg-thumbnail.js", "lg-video.js"], () => {
  //       // eslint-disable-next-line no-undef
  //       lightGallery(document.getElementById("lightgallery"), {
  //         thumbnail: true,
  //         youtubePlayerParams: { autoplay: 1, modestbranding: 1 },
  //       });
  //     });
  //   });
  // },
  mounted() {
    // eslint-disable-next-line no-undef
    require(["lightgallery.js"], () => {
      // eslint-disable-next-line no-undef
      require(["lg-thumbnail.js"], () => {
        // eslint-disable-next-line no-undef
        lightGallery(document.getElementById("lightgallery"), {
          thumbnail: true,
        });
        // eslint-disable-next-line no-undef
        lightGallery(document.getElementById("lightgallery2"), {
          thumbnail: true,
        });
      });
    });
  },
  computed: {
    favoritePhotos() {
      return this.$store.state?.mayor_page?.favorites_photos;
    },
    officialPhotos() {
      return this.$store.state?.mayor_page?.official_photos;
    },
  },
  methods: {
    async loadMore(index) {
      if (!this.loading) {
        this.loading = true;
        let url = "";
        if (index === 0) {
          url = this.favoritePhotos.next_page_url;
        } else if (index === 1) {
          url = this.officialPhotos.next_page_url;
        }
        await axios
          .get(url)
          .then(({ data }) => {
            this.loading = false;
            // добавляем к существующим новые документы с новой страницы пагинации
            if (index === 0) {
              this.$store.state.mayor_page.favorites_photos.data =
                this.$store.state.mayor_page.favorites_photos.data.concat(data.favorites_photos.data);
              this.$store.state.mayor_page.favorites_photos.next_page_url =
                data.favorites_photos.next_page_url;
            } else if (index === 1) {
              this.$store.state.mayor_page.official_photos.data =
                this.$store.state.mayor_page.official_photos.data.concat(data.official_photos.data);
              this.$store.state.mayor_page.official_photos.next_page_url = data.official_photos.next_page_url;
            }
          })
          .catch(({ response }) => {
            this.loading = false;
            console.error(response?.data?.message);
          });
      }
    },
    setActive(tab) {
      this.activeTab = tab;
    },
  },
};
</script>
