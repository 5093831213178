<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <MayorAbout />
        <MayorGallery />
      </div>
    </div>
  </main>
</template>

<script>
import MayorAbout from "./components/MayorAbout.vue";
import MayorGallery from "./components/MayorGallery.vue";

export default {
  name: "MayorBiographyPage",
  async asyncData({ store }) {
    await store.dispatch("GET_MAYOR_PAGE");
  },
  components: {
    MayorGallery,
    MayorAbout,
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
@import "~@/styles/parts/mayor/index.styl"
@import "~@/styles/parts/lightgallery.styl"
</style>
